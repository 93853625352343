/* mixins */
/* Custom mixins */
/* inspired by bootstrap */
/* Block newsletter */
#columns #newsletter_block_left .form-group {
  margin-bottom: 0; }
  #columns #newsletter_block_left .form-group .form-control {
    width: 100%;
    max-width: 222px;
    display: inline-block;
    margin-right: 6px; }
    @media (min-width: 992px) and (max-width: 1199px) {
      #columns #newsletter_block_left .form-group .form-control {
        margin-bottom: 10px;
        margin-right: 0; } }

#columns #newsletter_block_left .success_inline, #columns #newsletter_block_left .warning_inline {
  text-align: left;
  padding: 1px 0 0 0;
  margin-bottom: -19px; }

#columns #newsletter_block_left .success_inline {
  color: #418B19; }

#columns #newsletter_block_left .warning_inline {
  color: #f13340; }

/* Block newsletter footer */
#footer .footer-bottom {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 10px;
  margin-top: 15px; }

@media (max-width: 767px) {
  #footer #newsletter_block_left {
    width: 100%; } }

#footer #newsletter_block_left .newsletter-subtitle {
  font-size: 11px;
  font-weight: 300;
  line-height: 19px;
  margin: 14px 0;
  text-align: center; }

#footer #newsletter_block_left .block_content {
  overflow: hidden; }

#footer #newsletter_block_left .form-group {
  margin-bottom: 0;
  position: relative; }
  #footer #newsletter_block_left .form-group .form-control {
    height: 34px;
    line-height: 33px;
    max-width: 267px;
    background: transparent;
    border: solid rgba(255, 255, 255, 0.25);
    border-width: 0 0 1px;
    border-radius: 0;
    color: #fff;
    padding: 0 43px 0 0;
    margin: 0 0 10px;
    display: inline-block;
    float: left;
    font-size: 11px;
    font-weight: 300; }
    #footer #newsletter_block_left .form-group .form-control:focus {
      -webkit-box-shadow: black 0px 0px 0px;
      -moz-box-shadow: black 0px 0px 0px;
      box-shadow: black 0px 0px 0px; }
  #footer #newsletter_block_left .form-group .button-small {
    border: none;
    background: none;
    text-align: center;
    color: #908f8f;
    position: absolute;
    padding: 0 0 0 8px;
    top: 0;
    right: 0;
    color: #e86342; }
    #footer #newsletter_block_left .form-group .button-small:before {
      content: "\e02B";
      font-family: "CRUX";
      font-size: 12px;
      line-height: 34px; }
    #footer #newsletter_block_left .form-group .button-small:hover {
      color: #fff !important; }
    #footer #newsletter_block_left .form-group .button-small span {
      display: none; }

#footer #newsletter_block_left .warning_inline {
  display: block;
  color: #f13340;
  font-size: 13px;
  line-height: 26px;
  clear: both; }
  @media (min-width: 1200px) {
    #footer #newsletter_block_left .warning_inline {
      display: inline-block;
      position: relative;
      top: -35px;
      margin-bottom: -35px;
      left: 15px;
      clear: none; } }

#footer #newsletter_block_left .newsletter-input {
  max-width: 300px !important; }
